<template>
   <div id="app">
      <CRow>
         <CCol sm="8" md="8">
            <CCard>
               <CCardHeader>
                  <CRow>
                     <CCol col="6" class="text-left">
                        <h4>New Promotion</h4>
                     </CCol>
                     <CCol col="6" class="text-right">
                        <CButton v-on:click="actionSubmit()" style="width:100px" color="primary">Submit</CButton>
                     </CCol>
                  </CRow>
               </CCardHeader>
               <CCardBody>

                  <CForm>
                     <CRow>
                        <CCol col="12" class="text-left">
                           <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                        </CCol>
                     </CRow>

                     <b-tabs content-class="mt-3">
                        <b-tab title="General">
                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Promo Code</label>
                              </b-col>
                              <b-col sm="5">
                                 <!--b-form-input id="input-small" maxlength="10" placeholder="Type/Generate Code" v-model="promoCode"></b-form-input-->
                                 <CInput maxlength="10" placeholder="Type/Generate Code" v-model="promoCode"
                                    description="Max 10 Chars" />
                              </b-col>
                              <b-col sm="4">
                                 <CButton v-on:click="actionGenerate()" style="width:140px" color="success">Generate
                                 </CButton>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Promo Name</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input id="input-small" placeholder="Type Promo Name"
                                    v-model="promoName"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Promo Type</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-select @change="onChangePromoType" v-model="promoType"
                                    :options="optPromoType"></b-form-select>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Contact</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input id="input-small" placeholder="Type Contact"
                                    v-model="contact"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">From Currency</label>
                              </b-col>
                              <b-col sm="4">
                                 <b-form-select v-model="currency" :options="optCurrency"></b-form-select>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">To Currency</label>
                              </b-col>
                              <b-col sm="3">
                                 <b-form-select v-model="tocurrency" :options="optCurrency"></b-form-select>
                              </b-col>
                           </b-row>
                           <b-row>
                              <b-col sm="12">
                                 <label style="color:red; font-size : 13px;">* Currency filter only checked for Remit
                                    feature</label>
                              </b-col>
                           </b-row><br />
                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Start Time</label>
                              </b-col>
                              <b-col sm="5">
                                 <CInput type="date" v-model="fromDate" />
                              </b-col>
                              <b-col sm="4">
                                 <vue-timepicker @change="sttimeChange" v-model="fromTime"
                                    format="HH:mm:ss"></vue-timepicker>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">End Time</label>
                              </b-col>
                              <b-col sm="5">
                                 <CInput type="date" v-model="toDate" />
                              </b-col>
                              <b-col sm="4">
                                 <vue-timepicker @change="ettimeChange" v-model="endTime"
                                    format="HH:mm:ss"></vue-timepicker>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Highlights</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input id="input-small" placeholder="Type Highlights"
                                    v-model="highlights"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Exclusive</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cbexclusive" name="cbexclusive" value="true"
                                    unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Multiple Usage</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cbmusage" @change="onModifymUsage" name="cbmusage"
                                    value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Num of Usage:</label>
                              </b-col>
                              <b-col sm="5">
                                 <b-form-input type="number" :disabled="!isDisablednoupd" min=0
                                    v-model="numberOfUsagePerDay"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Limited Wallet Group</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cblimgroup" @change="onModifyLimGroup" name="cblimgroup"
                                    value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Wallet Groups:</label>
                              </b-col>
                              <b-col sm="5">
                                 <multiselect @select="toggleGroupSelected" @remove="toggleGroupUnSelect"
                                    v-model="userGroup" :disabled="!isDisabledlg" :options="optGroup" :multiple="true"
                                    :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                    placeholder="Pick Some" label="name" track-by="name" :preselect-first="true">
                                    <template slot="selection1" slot-scope="{ values, search, isOpen }"><span
                                          class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{
                                             values.length
                                          }} options selected</span></template>
                                 </multiselect>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Limited Merchant</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cblimchant" @change="onModifyLimMchn" name="cblimchant"
                                    value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Merchant:</label>
                              </b-col>
                              <b-col sm="5">
                                 <multiselect @select="toggleMerchantSelected" @remove="toggleMerchantUnSelect"
                                    v-model="merchant" :disabled="!isDisabledlc" :options="optMerchant" :multiple="true"
                                    :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                    placeholder="Pick Some" label="name" track-by="name" :preselect-first="true">
                                    <template slot="selection2" slot-scope="{ values, search, isOpen }"><span
                                          class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{
                                             values.length
                                          }} options selected</span></template>
                                 </multiselect>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Limited to User Phone</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cblimuser" @change="onModifyLimUsr" name="cblimuser"
                                    value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Users:</label>
                              </b-col>
                              <b-col sm="5">
                                 <multiselect @select="toggleUserSelected" @remove="toggleUserUnSelect" v-model="user"
                                    :disabled="!isDisabledlu" :options="optusers" :multiple="true"
                                    :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                    placeholder="Phone Number" label="name" track-by="name" :preselect-first="true">
                                    <template slot="selection3" slot-scope="{ values, search, isOpen }"><span
                                          class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{
                                             values.length
                                          }} options selected</span></template>
                                 </multiselect>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Limited Nationalities</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cblimnat" @change="onModifyLiNat" name="cblimnat"
                                    value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Nationalities:</label>
                              </b-col>
                              <b-col sm="5">
                                 <multiselect v-model="nations" :disabled="!isDisabledln" :options="optNations"
                                    :multiple="true" :close-on-select="false" :clear-on-select="false"
                                    :preserve-search="true" placeholder="Nationalities" label="name" track-by="name"
                                    :preselect-first="true">
                                    <template slot="selection4" slot-scope="{ values, search, isOpen }"><span
                                          class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{
                                             values.length
                                          }} options selected</span></template>
                                 </multiselect>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Limited to New User</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cblimnewuser" @change="onModifyNewUser" name="cblimnewuser"
                                    value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Num of Hours :</label>
                              </b-col>
                              <b-col sm="5">
                                 <b-form-input type="number" :disabled="!isDisabledNumHNewuser" min=0
                                    v-model="numberOfHoursForNewUser"></b-form-input>
                                 <label style="color:grey; font-size : 13px;">* No. of hours since user signed
                                    up</label>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Limited to No. of Redemptions</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cblimTGUsage" name="cblimTGUsage"
                                    @change="onChangelimTGUsage" value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Num. of Limits :</label>
                              </b-col>
                              <b-col sm="5">
                                 <b-form-input type="number" :disabled="!isDisabledNumGULimit" min=0
                                    v-model="numberGULimit"></b-form-input>
                              </b-col>
                           </b-row>

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Limited To Transaction Completed</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cblimTTrCompleted" name="cblimTTrCompleted"
                                    @change="onChangeCmplTransType" value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Transact Type</label>
                              </b-col>
                              <b-col sm="5">
                                 <b-form-select :disabled="!isDisabledcmplTransType" v-model="cmplTransType"
                                    :options="optcmplTransType"></b-form-select>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Limited User Tier</label>
                              </b-col>
                              <b-col sm="2">
                                 <b-form-checkbox v-model="cblimuserlvl" @change="onModifyLimUsrLvl" name="cblimuserlvl"
                                    value="true" unchecked-value="false">
                                    Yes
                                 </b-form-checkbox>
                              </b-col>
                              <b-col sm="2">
                                 <label for="input-small">Tiers:</label>
                              </b-col>
                              <b-col sm="5">                                 
                                 <b-form-select @change="onChangeUserTier" v-model="usrLvl" :options="optUsrLvl" :disabled="!isDisabledlul"></b-form-select>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Short TC</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input id="input-small" placeholder="Short TC" v-model="shortTc"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <CTextarea label="Terms And Conditions" v-model="termsAndConditions"
                              placeholder="Type Terms And Conditions" horizontal rows="3" />

                           <CTextarea label="Description" v-model="desc" placeholder="Type Description..." horizontal
                              rows="3" />

                        </b-tab>

                        <b-tab title="Benefit">
                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Benefit Type</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-select v-model="bentype" @change="onChangeBenType"
                                    :options="optbenType"></b-form-select>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Amount/Points</label>
                              </b-col>
                              <b-col sm="4">
                                 <b-form-input @change="onChangeBenAmount" type="number" min=0 step="any"
                                    v-model="amount"></b-form-input>
                              </b-col>
                              <b-col sm="1">
                                 <label for="input-small">Max.</label>
                              </b-col>
                              <b-col sm="4">
                                 <b-form-input type="number" min=0 step="any" v-model="max"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Min. Spend</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input type="number" min=0 step="any" :disabled="!isDisabledmin"
                                    v-model="min"></b-form-input>
                                 <label style="color:grey; font-size : 13px;">* transaction amount should be greater
                                    than or equal to this amount</label>
                              </b-col>
                           </b-row>

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Qualifying Transaction Amount</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input type="number" min=0 step="any" :disabled="!isDisabledpTL"
                                    v-model="pointTransactionLimit"></b-form-input>
                                 <label style="color:grey; font-size : 13px;">* for POINTS use only</label>
                              </b-col>
                           </b-row>

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Referee Points</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input type="number" min=0 step="any" v-model="refereePoints"
                                    :disabled="!isDisabledrp1"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Referrer Points</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input type="number" min=0 step="any" v-model="referrerPoints"
                                    :disabled="!isDisabledrp2"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Points Validity Period</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-input type="number" min=0 v-model="valPeriod"
                                    :disabled="!isDisabledvp"></b-form-input>
                              </b-col>
                           </b-row><br />

                           <b-row>
                              <b-col sm="3">
                                 <label for="input-small">Points Validity Unit</label>
                              </b-col>
                              <b-col sm="9">
                                 <b-form-select v-model="valUnit" :options="optvalUnit"
                                    :disabled="!isDisabledvu"></b-form-select>
                              </b-col>
                           </b-row><br />
                        </b-tab>
                     </b-tabs>

                  </CForm>
               </CCardBody>
            </CCard>
         </CCol>
      </CRow>

   </div>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import PromoService from '@/api/PromoService.js';
import TrService from '@/api/TransactService.js';
import VueTimepicker from 'vue2-timepicker';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import listOfNation from '@/assets/nationalities.json';

export default {
   name: 'limits',
   components: {
      VueTypeaheadBootstrap,
      Multiselect,
      VueTimepicker
   },
   data() {
      var date = new Date();
      return {
         submit: true,
         isDisabledrp1: false,
         isDisabledrp2: false,
         isDisabledvp: false,
         isDisabledvu: false,
         isDisabledlg: false,
         isDisabledlc: false,
         isDisabledlu: false,
         isDisabledlul: false,
         isDisablednoupd: false,
         isDisabledmin: false,
         isDisabledpTL: false,
         isDisabledln: false,
         isDisabledNumHNewuser: false,
         isDisabledNumGULimit: false,
         isDisabledcmplTransType: false,
         promoCode: '',
         promoName: '',
         promoType: '',
         optPromoType: [],
         currency: null,
         tocurrency: null,
         contact: '',
         highlights: '',
         shortTc: '',
         termsAndConditions: '',
         numberOfUsagePerDay: 0,
         numberOfHoursForNewUser: 0,
         numberGULimit: 0,
         min: 0,
         pointTransactionLimit: 0,
         optCurrency: [],
         fromDate: new Date().toISOString().substr(0, 10),
         toDate: new Date().toISOString().substr(0, 10),
         fromTime: moment.utc(date).local().format('HH:mm:ss'),
         endTime: '23:59:59',
         cbmusage: false,
         cbexclusive: false,
         cblimgroup: false,
         cblimchant: false,
         cblimuser: false,
         cblimuserlvl: false,
         cblimnewuser: false,
         cblimTGUsage: false,
         cblimTTrCompleted: false,
         cmplTransType: null,
         optcmplTransType: [],
         userGroup: null,
         optGroup: [],
         baseoptGroup: [],
         merchant: null,
         optMerchant: [
            { name: 'MERCHANT1' },
            { name: 'MERCHANT2' },
         ],
         user: null,
         baseUserId: [],
         basePhoneNum: [],
         optusers: [],
         cblimnat: '',
         nations: [],
         optNations: [],
         desc: '',
         bentype: '',
         optbenType: [],
         amount: 0,
         max: 0,
         refereePoints: 0,
         referrerPoints: 0,
         valPeriod: 1,
         valUnit: "YEARS",
         optvalUnit: [
            { value: 'HOURS', text: 'HOURS' },
            { value: 'DAYS', text: 'DAYS' },
            { value: 'WEEKS', text: 'WEEKS' },
            { value: 'MONTHS', text: 'MONTHS' },
            { value: 'YEARS', text: 'YEARS' }
         ],
         version: 'null',
         optVersion: [],
         listGroupId: [],
         usrLvl: null,
         optUsrLvl: [],
         seen: false,
         msg: '',
         color: ''
      };
   },
   created() {
      this.getConfigList();
      this.getWalletGroupList();
      this.getMerchantList();
      this.fetchUsers();
   },
   methods: {

      validateSubmit(params) {

         var data = [
            { key: 'multipleUsage', pair: 'numberOfUsagePerDay', msg: 'Num of Usage doesnt have a value !' },
            { key: 'limitedToGroup', pair: 'useableGroups', msg: 'Wallet Groups doesnt have a value !' },
            { key: 'limitedToMerchant', pair: 'useableMerchants', msg: 'Merchant doesnt have a value !' },
            { key: 'limitedToUsers', pair: 'useableUsers', msg: 'Phone Number doesnt have a value !' },
            { key: 'limitedToNationality', pair: 'allowedNationalities', msg: 'Nationalities doesnt have a value !' },
            { key: 'limitedToNewUser', pair: 'numberOfHoursForNewUser', msg: 'Num of Hours doesnt have a value !' },
            { key: 'limitedToGlobalUsage', pair: 'globalUsageLimit', msg: 'Num. of Limits doesnt have a value !' },
            { key: 'limitedToTransactionCompleted', pair: 'cmplTransType', msg: 'Transact Type doesnt have a value !' },
            { key: 'limitedToTier', pair: 'userTier', msg: 'Tiers doesnt have a value !' }
         ];

         var validate = []; var errMsg = []; let result = {}
         for (var key in data) {
            var keyName = data[key].key;
            var pairname = data[key].pair;
            var isMulti = Array.isArray(params[pairname]) ? true : false;
            var msg = data[key].msg;
            if (isMulti && params[keyName] && (params[pairname].length == 0)) {
               validate.push(false); errMsg.push(msg);
            } else if (params[keyName] && !params[pairname]) {
               validate.push(false); errMsg.push(msg);
            }
         }

         if (validate.includes(false)) {
            result['status'] = false; result['msg'] = errMsg.join(" , ");
         } else {
            result['status'] = true; result['msg'] = "Success";
         }

         return result;
      },

      actionSubmit() {

         var fromDate = this.fromDate;
         var toDate = this.toDate;
         var fromTime = !this.fromTime ? "00:00:00" : this.fromTime;
         var endTime = !this.endTime ? "00:00:00" : this.endTime;
         var startTime = fromDate + "T" + fromTime;
         var endTime = toDate + "T" + endTime;

         let params = {};
         let benefits = {};
         benefits['amount'] = parseFloat(this.amount),
         benefits['benefitType'] = this.bentype,
         benefits['max'] = parseFloat(this.max),
         benefits['refereePoints'] = parseFloat(this.refereePoints),
         benefits['referrerPoints'] = parseFloat(this.referrerPoints),
         benefits['validityPeriod'] = this.valPeriod,
         benefits['validityUnit'] = this.valUnit;
         params['benefit'] = benefits;
         params['contact'] = this.contact;
         params['currency'] = this.currency;
         params['toCurrency'] = this.tocurrency;
         params['description'] = this.desc;
         params['endTime'] = endTime;
         params['exclusive'] = this.stringToBoolean(this.cbexclusive);
         params['highlights'] = this.highlights;

         params['limitedToGroup'] = this.stringToBoolean(this.cblimgroup);
         params['limitedToMerchant'] = this.stringToBoolean(this.cblimchant);
         params['limitedToUsers'] = this.stringToBoolean(this.cblimuser);
         params['limitedToNationality'] = this.stringToBoolean(this.cblimnat);
         params['limitedToTier'] = this.stringToBoolean(this.cblimuserlvl);

         params['limitedToNewUser'] = this.stringToBoolean(this.cblimnewuser);
         params['limitedToGlobalUsage'] = this.stringToBoolean(this.cblimTGUsage);
         params['limitedToTransactionCompleted'] = this.stringToBoolean(this.cblimTTrCompleted);

         params['min'] = this.min;
         params['multipleUsage'] = this.stringToBoolean(this.cbmusage);
         params['numberOfUsagePerDay'] = this.numberOfUsagePerDay;
         params['pointTransactionLimit'] = this.pointTransactionLimit;

         params['promoCode'] = this.promoCode;
         params['promoName'] = this.promoName;
         params['promotionType'] = this.promoType;
         params['shortTc'] = this.shortTc;
         params['startTime'] = startTime;
         params['termsAndConditions'] = this.termsAndConditions;

         params['numberOfHoursForNewUser'] = this.numberOfHoursForNewUser;
         params['globalUsageLimit'] = this.numberGULimit;
         params['cmplTransType'] = this.cmplTransType;

         var arrUserGroup = [];
         if (this.userGroup !== 'undefined') {
            for (var key in this.userGroup) {
               var ugroup = this.userGroup[key].name;
               var groupId = this.userGroup[key].id;
               arrUserGroup.push(groupId);
            }
         }
         params['useableGroups'] = arrUserGroup;

         var arrMerchant = [];
         if (this.merchant !== 'undefined') {
            for (var key in this.merchant) {
               var mrchn = this.merchant[key].id;
               arrMerchant.push(String(mrchn));
            }
         }
         params['useableMerchants'] = arrMerchant;

         var arrNations = [];
         if (this.nations !== 'undefined') {
            for (var key in this.nations) {
               var natkey = this.nations[key].id;
               arrNations.push(String(natkey));
            }
         }
         params['allowedNationalities'] = arrNations;

         var arrUsers = [];
         var baseUserId = this.baseUserId;
         var basePhoneNum = this.basePhoneNum;

         if (this.user !== 'undefined') {
            for (var key in this.user) {
               var phoneNum = this.user[key].name;
               let index = basePhoneNum.findIndex(basePhoneNum => basePhoneNum === phoneNum);
               var userId = baseUserId[index];
               arrUsers.push(userId);
            }
         }
         params['useableUsers'] = arrUsers;
         params['userTier'] = this.usrLvl;

         var fields = ["promoCode", "promoName", "promotionType", "currency", "description"];
         var validate = [];
         for (var key in fields) {
            var keyName = fields[key];
            var status = !params[keyName] ? false : true;
            validate.push(status);
         }

         var validBenType = (!params['benefit']['benefitType']) ? false :true;
         validate.push(validBenType);

         validate.push(this.submit);
         var pairValidate = this.validateSubmit(params);

         if (!pairValidate.status) {
            this.msg = pairValidate.msg;
            this.color = 'warning'; this.seen = true;
            setTimeout(() => this.seen = false, 5000);
         } else if (validate.includes(false) || !this.submit) {
            var msg1 = "Amount not allow a higher than Fee, or Tier Not match with the Fee!";
            var msg2 = 'Data Not Complete, All fields required !';
            this.msg = (!this.submit) ? msg1 : msg2;
            this.color = 'warning'; this.seen = true;
            setTimeout(() => this.seen = false, 5000);
         } else { //console.log(params);
            this.createNewPromo(params);
         }

      },

      stringToBoolean: function (string) {
         switch (String(string).toLowerCase().trim()) {
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
         }
      },

      sttimeChange() {
         var term = this.fromTime; var temp = this.fromTime;

         if (term.match(/HH|mm|ss/g)) {
            var temp = term.replace("HH", "00").replace("mm", "00").replace("ss", "00");
         }

         this.fromTime = temp;

      },

      ettimeChange() {

         var term = this.endTime; var temp = this.endTime;

         if (term.match(/HH|mm|ss/g)) {
            var temp = term.replace("HH", "00").replace("mm", "00").replace("ss", "00");
         }

         this.endTime = temp;

      },

      async onChangeBenAmount() {

         if (this.bentype == "DISCOUNT_FIXED") {            
            var trType = (this.promoType == "TOPUP") ? "TOP_UP" : this.promoType;
            let params = {'feeType': 'FIXED', 'transactionType': trType};
            const resp = await TrService.findFeeByType(params);
            if (resp.message == "Success") {
               var ben_amount = this.amount; var usrLvl = this.usrLvl;
               var validateFee = []; var validateTier = []; this.submit = true;
               var data = resp.payload;
               for (var key in data) {
                  var fee_amount = data[key].amount; var userTier = data[key].userTier;
                  console.log('ben :' + ben_amount + ' > fee:' + fee_amount + ' ?');
                  var isFeeValid = (ben_amount > fee_amount) ? false : true; validateFee.push(isFeeValid);
                  if (usrLvl !== '' && usrLvl !== null) {
                     console.log('form :' + usrLvl + '| promo:' + userTier);
                     var isTierValid = (usrLvl != userTier) ? false : true;
                     validateTier.push(isTierValid);
                  }
               }

               if (validateFee.includes(false) || validateTier.includes(false)) {
                  if (validateFee.includes(false)) {
                     this.msg = "Amount not allow a higher than Fee!";
                  } else { this.msg = "Tier not match with Tier Fee !"; }
                  this.color = "warning"; this.seen = true; this.submit = false;
                  setTimeout(() => this.seen = false, 5000);
               }
            }
         }

      },

      onChangePromoType() {         
         this.onChangeBenType();
         this.onChangeBenAmount();
      },

      onChangeUserTier(){
         this.onChangeBenType();
         this.onChangeBenAmount();
      },

      async onChangeBenType() {

         if (this.bentype == "POINTS") {
            this.isDisabledvp  = true;
            this.isDisabledvu  = true;
            this.isDisabledrp1 = true;
            this.isDisabledrp2 = true;
            this.isDisabledpTL = true;
            this.isDisabledmin = false;
         } else if (this.bentype.substr(-5) === "FIXED") {
            /*var checkPromoType  = ['BUY_NOW', 'BUYNOW_SGQR'];
            var trType = (this.promoType == "TOPUP") ? "TOP_UP" : this.promoType;
            let params = {'feeType': null, 'transactionType': trType};            
            const resp = await TrService.findFeeByType(params); 
            if (resp.message == "Success") {
               var data = resp.payload;
               for (var key in data) {
                  var feeType = data[key].feeType; var userTier = data[key].userTier; 
                  console.log(feeType +'= PERCENTAGE |'+this.usrLvl+'='+userTier); 
                  if(checkPromoType.includes(this.promoType) && feeType === "PERCENTAGE" && this.usrLvl === userTier){
                     this.bentype = '';
                     this.msg   = "Can't using FIXED Benefit Type !";
                     this.color = 'warning'; this.seen = true;
                     setTimeout(() => this.seen = false, 5000);
                  }               
               }
            }*/
            
            this.onChangeBenAmount();            

         } else {
            this.isDisabledvp  = false;
            this.isDisabledvu  = false;
            this.isDisabledrp1 = false;
            this.isDisabledrp2 = false;
            this.isDisabledpTL = false;
            this.isDisabledmin = true;
         }

      },

      toggleGroupSelected() {

      },

      toggleGroupUnSelect() {

      },

      toggleMerchantSelected() {

      },

      toggleMerchantUnSelect() {

      },

      toggleUserUnSelect() {

      },

      toggleUserSelected() {

      },

      onModifymUsage() {
         if (this.cbmusage == "true") {
            this.isDisablednoupd = true;
         } else { this.isDisablednoupd = false; }
      },

      onModifyNewUser() {
         if (this.cblimnewuser == "true") {
            this.isDisabledNumHNewuser = true;
         } else { this.isDisabledNumHNewuser = false; this.numberOfHoursForNewUser = 0; }
      },

      onChangeCmplTransType() {
         if (this.cblimTTrCompleted == "true") {
            this.isDisabledcmplTransType = true;
         } else { this.isDisabledcmplTransType = false; this.cmplTransType = null; }
      },

      onChangelimTGUsage() {
         if (this.cblimTGUsage == "true") {
            this.isDisabledNumGULimit = true;
         } else { this.isDisabledNumGULimit = false; this.numberGULimit = 0; }
      },

      onModifyLimUsr() {
         if (this.cblimuser == "true") {
            this.isDisabledlu = true;
         } else { this.isDisabledlu = false; }
      },

      onModifyLimUsrLvl() {
         if (this.cblimuserlvl == "true") {
            this.isDisabledlul = true;
         } else { this.isDisabledlul = false; this.usrLvl = null; }
      },

      onModifyLimMchn() {
         if (this.cblimchant == "true") {
            this.isDisabledlc = true;
         } else { this.isDisabledlc = false; }
      },

      onModifyLimGroup() {
         if (this.cblimgroup == "true") {
            this.isDisabledlg = true; this.optGroup = this.baseoptGroup;
         } else { this.isDisabledlg = false; this.optGroup = []; }
      },

      onModifyLiNat() {
         if (this.cblimnat == "true") {
            this.isDisabledln = true; this.getNationalityList();
         } else { this.isDisabledln = false; this.optNations = []; }
      },

      uniqueString: function (length) {
         var result = '';
         var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
         var charactersLength = characters.length;
         for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
         }
         return result;
      },

      actionGenerate() {

         var code = this.uniqueString(10)
         this.promoCode = code;

      },

      convertCurrKey() {
         let result = {};
         for (var key in Currency) {
            var value = Currency[key];
            for (var key2 in Phone) {
               var val = Phone[key2];
               if (key2 == key) {
                  result[value] = val;
               }
            }
         }
         //console.log(result);
      },

      createNewPromo: function (params) {
         PromoService.createNewPromo(params).then(resp => {
            console.log(resp);
            if (resp.message === "Success") {
               this.msg = "Create New Promotion Success!";
               this.color = 'success'; this.seen = true;
            } else {
               this.msg = !resp.data.message ? "Create New Promotion Failed!" : resp.data.message;
               this.color = 'danger'; this.seen = true;
            }

            setTimeout(() => this.seen = false, 5000);

         }, error => {
            this.loading = false;
         });
      },

      fetchUsers: function () {
         AuthService.getUserList({ page: 0, size: 10000 }).then(resp => {

            var users = resp.payload;
            var items = []; var userId = []; var basePhoneNum = [];

            for (var key in users) {
               var countryCode = users[key].mobileNumber.countryCode;
               var phoneNum = users[key].mobileNumber.number;
               var ccphoneNum = countryCode + phoneNum;   //console.log(ccphoneNum);
               //var phoneNum = users[key].mobileNumber.number;
               items.push({ name: ccphoneNum });
               userId.push(users[key].userIdentify.userId);
               basePhoneNum.push(ccphoneNum);
            }

            //console.log(items);
            this.optusers = items;
            this.baseUserId = userId;
            this.basePhoneNum = basePhoneNum;

         }, error => {
            this.loading = false;
         });
      },

      getMerchantList: function () {
         PromoService.getMerchantList({ page: 0, size: 10000 }).then(resp => { //console.log(resp);

            var mchSrc = [];
            for (var key in resp) {
               var name = resp[key].displayName + ' - ' + resp[key].branchName;
               var userId = resp[key].merchantIdentify.userId;
               mchSrc.push({ id: userId, name: name }); name = '';

            } //console.log(mchSrc);

            this.optMerchant = mchSrc;

         }, error => {
            console.log('error');
         });
      },

      getWalletGroupList: function () {
         AuthService.getWalletGroupList().then(resp => { //console.log(resp);

            var items = resp; var wallets = []; var temp = [];
            for (var key in items) {
               var GroupId = items[key].id;
               var GroupName = items[key].name;
               var GroupUId = items[key].groupUniqueNumber;
               temp.push(GroupName + "|" + GroupUId + "|" + GroupId);

            } temp.sort();
            for (var key2 in temp) {
               var value = temp[key2].split("|");
               var name = value[0] + ' (' + value[1] + ') ';
               var GroupId = value[2];
               wallets.push({ id: GroupId, name: name }); name = '';

            }

            this.optGroup = wallets;
            this.baseoptGroup = wallets;


         }, error => {
            this.loading = false;
         });
      },

      getNationalityList() {
         var list = listOfNation; var result = [];
         for (var key in list) {
            var name = list[key];
            result.push({ id: key, name: name });
         }

         this.optNations = result;
      },

      sortByProperty(property) {
         return function (a, b) {
            if (a[property] > b[property])
               return 1;
            else if (a[property] < b[property])
               return -1;
            return 0;
         }
      },

      getConfigList: function () {
         AuthService.getConfigList().then(resp => {  //console.log(resp);

            var currSrc = []; var temp = [];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency) { temp.push(item); }
            temp.sort();
            currSrc.push({ value: null, text: "" });
            for (var key in temp) {
               var curr = temp[key];
               currSrc.push({ value: curr, text: curr });
            }
            this.optCurrency = currSrc;

            var bentSrc = [];
            var bentype = resp.promoBenefitTypes;
            for (var key2 in bentype) {
               var ben = bentype[key2];
               bentSrc.push({ value: ben, text: ben });
            }

            bentSrc.sort(this.sortByProperty("value"));
            this.optbenType = bentSrc;

            var promoSrc = [];
            var promoType = resp.promoTypes;
            for (var key3 in promoType) {
               var promo = promoType[key3];
               promoSrc.push({ value: promo, text: promo });
            }

            promoSrc.sort(this.sortByProperty("value"));
            this.optPromoType = promoSrc;

            var usrTLType = resp.userTierList; var userTLSrc = [];
            for (var key in usrTLType) {
               var usrTLName = usrTLType[key];
               userTLSrc.push({ value: usrTLName, text: usrTLName });
            }
            this.optUsrLvl = userTLSrc;

            var cTrType = resp.promoCmplTrnsList; var cTrTypeSrc = [];
            for (var key in cTrType) {
               var trType = cTrType[key];
               cTrTypeSrc.push({ value: trType, text: trType });
            }
            cTrTypeSrc.sort(this.sortByProperty("value"));
            this.optcmplTransType = cTrTypeSrc;

         }, error => {
            console.log('error');
         });
      },
   }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>